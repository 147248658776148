import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We recommend you follow this order while implementing:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Implementation Step`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required for PQs?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required for APQs?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1. Generate the operation manifest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "role": "img",
              "aria-label": "check mark button"
            }}>{`✅`}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2. Publish the operation manifest to a PQL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "role": "img",
              "aria-label": "check mark button"
            }}>{`✅`}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3. Enable persisted queries on the client when it makes requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "role": "img",
              "aria-label": "check mark button"
            }}>{`✅`}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "role": "img",
              "aria-label": "check mark button"
            }}>{`✅`}</span></td>
        </tr>
      </tbody>
    </table>
    <p>{`The rest of this article details these steps.`}</p>
    <p>{`Persisted queries also require you to create and link a PQL, and to configure your router to receive persisted query requests. This document only describes the steps that need to be taken by the client to create a manifest of the client's operations and send persisted query requests. For more information on the other configuration aspects of persisted queries, see the `}<a parentName="p" {...{
        "href": "/graphos/operations/persisted-queries"
      }}>{`GraphOS persisted queries documentation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      