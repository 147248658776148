import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Tip mdxType="Tip">
      <p>{`Ensure your Rover CLI version is `}<inlineCode parentName="p">{`0.17.2`}</inlineCode>{` or later. Previous versions of Rover don't support publishing operations to a PQL.
`}<a parentName="p" {...{
          "href": "/rover/getting-started/"
        }}>{`Download the latest version.`}</a></p>
    </Tip>
    <p>{`After you `}<a parentName="p" {...{
        "href": "/graphos/operations/persisted-queries#31-generate-persisted-query-manifests"
      }}>{`generate an operation manifest`}</a>{`, you publish it to your PQL with the `}<a parentName="p" {...{
        "href": "/rover/"
      }}>{`Rover CLI`}</a>{` like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "showLineNumbers=false title=\"Example command\"",
        "showLineNumbers": "false",
        "title": "\"Example",
        "command\"": true
      }}>{`rover persisted-queries publish my-graph@my-variant \\
  --manifest ./persisted-query-manifest.json
`}</code></pre>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`my-graph@my-variant`}</inlineCode>{` argument is the `}<a parentName="li" {...{
          "href": "/rover/conventions#graph-refs"
        }}><inlineCode parentName="a">{`graph ref`}</inlineCode></a>{` of any variant the PQL is `}<a parentName="li" {...{
          "href": "/graphos/operations/persisted-queries#12-link-the-pql-to-variants"
        }}>{`linked to`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`Graph refs have the format `}<inlineCode parentName="li">{`graph-id@variant-name`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`--manifest`}</inlineCode>{` option to provide the path to the manifest you want to publish.`}</li>
    </ul>
    <Note mdxType="Note">
      <p>{`The `}<inlineCode parentName="p">{`persisted-queries publish`}</inlineCode>{` command assumes manifests are in the `}<a parentName="p" {...{
          "href": "/graphos/operations/persisted-queries#manifest-format"
        }}>{`format`}</a>{` generated by Apollo client tools. The command can also support manifests `}<a parentName="p" {...{
          "href": "https://relay.dev/docs/guides/persisted-queries/#local-persisted-queries"
        }}>{`generated by the Relay compiler`}</a>{` by adding the `}<a parentName="p" {...{
          "href": "/rover/commands/persisted-queries/#relay-support"
        }}><inlineCode parentName="a">{`--manifest-format relay`}</inlineCode></a>{` argument. Your Rover CLI version must be 0.19.0 or later to use this argument.`}</p>
    </Note>
    <p>{`The `}<inlineCode parentName="p">{`persisted-queries publish`}</inlineCode>{` command does the following:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Publishes all operations in the provided manifest file to the PQL linked to the specified variant, or to the specified PQL.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Publishing a manifest to a PQL is additive. Any existing entries in the PQL remain.`}</li>
          <li parentName="ul">{`If you publish an operation with the same `}<inlineCode parentName="li">{`id`}</inlineCode>{` but different details from an existing entry in the PQL, the entire publish command fails with an error.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Updates any other variants that the PQL is applied to so that routers associated with those variants can fetch their updated PQL.`}</p>
      </li>
    </ol>
    <p>{`As with `}<a parentName="p" {...{
        "href": "/graphos/operations/persisted-queries#31-generate-persisted-query-manifests"
      }}>{`generating manifests`}</a>{`, it's best to execute this command in your CI/CD pipeline to publish new operations as part of your app release process. The API key you supply to Rover must have the `}<a parentName="p" {...{
        "href": "/graphos/org/members#graph-api-key-roles"
      }}>{`role`}</a>{` of `}<strong parentName="p">{`Graph Admin`}</strong>{` or `}<strong parentName="p">{`Persisted Query Publisher`}</strong>{`. `}<strong parentName="p">{`Persisted Query Publisher`}</strong>{` is a special role designed for use with the `}<inlineCode parentName="p">{`rover persisted-queries publish`}</inlineCode>{` command; API keys with this role have no other access to your graph's data in GraphOS, and are appropriate for sharing with trusted third party client developers who should be allowed to publish operations to your graph's PQL but should not otherwise have access to your graph.`}</p>
    <h4 {...{
      "id": "test-operations"
    }}><a parentName="h4" {...{
        "href": "#test-operations"
      }}>{`Test operations`}</a></h4>
    <p>{`You can send some test operations to test that you've successfully published your manifests:`}</p>
    <p>{`First, start your GraphOS-connected router:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "showLineNumbers=false",
        "showLineNumbers": "false"
      }}>{`APOLLO_KEY="..." APOLLO_GRAPH_REF="..." ./router --config ./router.yaml

2023-05-11T15:32:30.684460Z  INFO Apollo Router v1.18.1 // (c) Apollo Graph, Inc. // Licensed as ELv2 (https://go.apollo.dev/elv2)
2023-05-11T15:32:30.684480Z  INFO Anonymous usage data is gathered to inform Apollo product development.  See https://go.apollo.dev/o/privacy for details.
2023-05-11T15:32:31.507085Z  INFO Health check endpoint exposed at http://127.0.0.1:8088/health
2023-05-11T15:32:31.507823Z  INFO GraphQL endpoint exposed at http://127.0.0.1:4000/ 🚀
`}</code></pre>
    <p>{`Next, make a POST request with `}<inlineCode parentName="p">{`curl`}</inlineCode>{`, like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "showLineNumbers=false",
        "showLineNumbers": "false"
      }}>{`curl http://localhost:4000 -X POST --json \\
'{"extensions":{"persistedQuery":{"version":1,"sha256Hash":"dc67510fb4289672bea757e862d6b00e83db5d3cbbcfb15260601b6f29bb2b8f"}}}'
`}</code></pre>
    <p>{`If your router's PQL includes an operation with an ID that matches the value of the provided `}<inlineCode parentName="p">{`sha256Hash`}</inlineCode>{` property, it executes the corresponding operation and returns its result.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      