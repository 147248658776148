import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Apollo supports two separate but related features called `}<em parentName="p">{`automatic persisted queries`}</em>{` (APQs) and `}<em parentName="p">{`persisted queries`}</em>{`.
With both features, clients can execute a GraphQL operation by sending an operation's ID instead of the entire operation string. An operation's ID is a hash of the full operation string. Querying by ID can significantly reduce latency and bandwidth usage for large operation strings.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      