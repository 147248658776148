import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Stack } from '@chakra-ui/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Stack spacing="3" align="flex-start" borderWidth="1px" rounded="lg" p="6" mt="10" mdxType="Stack">
      <h4 {...{
        "id": "large-team-or-enterprise"
      }}><a parentName="h4" {...{
          "href": "#large-team-or-enterprise"
        }}>{`Large team or Enterprise?`}</a></h4>
      <p>{`GraphOS Studio provides core GraphQL schema management features to all users for free, along with advanced security, validation, and integration features to organizations with an `}<a parentName="p" {...{
          "href": "https://www.apollographql.com/pricing"
        }}>{`Apollo Team or Enterprise plan`}</a>{`.`}</p>
      <Button colorScheme="navy" as="a" target="_blank" rel="noopener noreferrer" href="https://studio.apollographql.com/signup?utm_source=apollo-docs" mdxType="Button">
  Sign up
      </Button>
    </Stack>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      