import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">
        <p parentName="li">{`If you haven't yet:`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/rover/getting-started/"
            }}>{`Install the Rover CLI`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/rover/configuring/"
            }}>{`Authenticate Rover`}</a>{` with GraphOS Studio`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Decide how you'll provide your server's schema to Rover. You can either:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Use a `}<inlineCode parentName="li">{`.gql`}</inlineCode>{` or `}<inlineCode parentName="li">{`.graphql`}</inlineCode>{` file saved on your local machine, or`}</li>
          <li parentName="ul">{`Perform an introspection query on your running server to fetch the schema`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the `}<inlineCode parentName="p">{`rover graph publish`}</inlineCode>{` command, providing your schema in one of the ways shown:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash",
            "metastring": "showLineNumbers=false",
            "showLineNumbers": "false"
          }}>{`# Provide a local .graphql file path
rover graph publish my-graph@my-variant --schema ./schema.graphql

# Provide an introspection result via stdin
rover graph introspect http://localhost:4000 | rover graph publish my-graph@my-variant --schema -
`}</code></pre>
        <p parentName="li">{`As shown, the first positional argument you provide `}<inlineCode parentName="p">{`rover graph publish`}</inlineCode>{` is a `}<a parentName="p" {...{
            "href": "/rover/conventions/#graph-refs"
          }}>{`graph ref`}</a>{`, a string that specifies a particular variant of a particular graph in Studio.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      