import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As soon as a graph variant has a linked PQL, you can configure router instances to fetch and use the PQL by following these steps:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Ensure your router instances are ready to work with PQLs:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Make sure you're using version `}<inlineCode parentName="li">{`1.32.0`}</inlineCode>{` or later of the Apollo Router. (The feature was released in `}<a parentName="li" {...{
              "href": "/resources/product-launch-stages/#preview"
            }}>{`preview`}</a>{` in version `}<inlineCode parentName="li">{`1.25.0`}</inlineCode>{` and made `}<a parentName="li" {...{
              "href": "/resources/product-launch-stages/#general-availability"
            }}>{`generally available`}</a>{` in `}<inlineCode parentName="li">{`1.32.0`}</inlineCode>{`.)`}</li>
          <li parentName="ul">{`Make sure your router instances are `}<a parentName="li" {...{
              "href": "/router/enterprise-features/#enabling-enterprise-features"
            }}>{`connected to your GraphOS Enterprise organization`}</a>{` and that they're associated with a variant that your PQL is linked to.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Set your desired security level in your router's YAML config file. For supported options, see `}<a parentName="p" {...{
            "href": "#router-security-levels"
          }}>{`router security levels`}</a>{`. When first implementing persisted queries, it's best to start with `}<a parentName="p" {...{
            "href": "#audit-mode-dry-run"
          }}>{`audit—or "dry run"—mode`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Deploy your updated router instances to begin using your PQL.`}</p>
      </li>
    </ol>
    <p>{`Once your organization's PQL has registered all your clients' operations and you've ensured your client apps are only sending registered operations, you can update your router configuration to the `}<a parentName="p" {...{
        "href": "#safelisting"
      }}>{`safelisting security level`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      