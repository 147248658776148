import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to your organization's `}<strong parentName="p">{`Graphs`}</strong>{` tab in `}<a parentName="p" {...{
            "href": "https://studio.apollographql.com/?referrer=docs-content"
          }}>{`GraphOS Studio`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<strong parentName="p">{`Create New Graph`}</strong>{` in the top right.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the dialog that appears, specify an `}<strong parentName="p">{`Organization`}</strong>{` and `}<strong parentName="p">{`Graph title`}</strong>{` for your graph.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Leave the `}<strong parentName="p">{`Graph Architecture`}</strong>{` as `}<strong parentName="p">{`Supergraph (Default)`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<strong parentName="p">{`Next`}</strong>{`. A modal like the following appears:`}</p>
      </li>
    </ol>
    <img src="/e00d9adacfce9970892900837c73bbb7/register-schema-dialog.jpg" alt="Schema registration options" width="500" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Leave the `}<strong parentName="li">{`Supergraph Pipeline Track`}</strong>{` dropdown set to its default value.`}</li>
    </ol>
    <p>{`Your graph has been created!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      