import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="Click to expand" mdxType="ExpansionPanel">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Page`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`README`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays the variant's editable Markdown-formatted README, its graph ref and router URL, and other important details about it. `}<a parentName="td" {...{
                "href": "/graphos/graphs/studio-features/#the-readme-page"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Schema`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Documentation for all your supergraph's types and fields. Supports filtering definitions by name, subgraph, and other values. `}<a parentName="td" {...{
                "href": "/graphos/graphs/studio-features/#the-schema-page"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Explorer`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A powerful IDE for building and executing operations against your supergraph. `}<a parentName="td" {...{
                "href": "/graphos/explorer/"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Changelog`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays a historical diff of additions, modifications, and deletions made to your supergraph's schema.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Subgraphs`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays the details for each subgraph (GraphQL API) that's part of your supergraph. `}<a parentName="td" {...{
                "href": "/graphos/cloud-routing"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Proposals`}</strong>{` (Enterprise only)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays a graph's list of schema proposals. `}<a parentName="td" {...{
                "href": "/graphos/delivery/schema-proposals/"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Clients`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays operation metrics for the variant, broken down by individual clients. This feature requires that clients identify themselves in their requests to your router. `}<a parentName="td" {...{
                "href": "/graphos/metrics/client-awareness"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Insights`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays usage and performance metrics for the variant at the operation and field levels. This includes request and error rates. `}<a parentName="td" {...{
                "href": "/graphos/metrics/"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Checks`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays the results of schema checks that have been run for the variant. `}<a parentName="td" {...{
                "href": "/graphos/delivery/schema-checks"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Launches`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Displays the results of recent launches executed for your supergraph. `}<a parentName="td" {...{
                "href": "/graphos/delivery/launches"
              }}>{`Learn more.`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Settings`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Configuration options for the individual variant, along with high-level options for the entire supergraph.`}</td>
          </tr>
        </tbody>
      </table>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      