import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Box, Center, Flex, Link, Text, chakra } from '@chakra-ui/react';
import { EnterpriseIcon } from '../../components/Icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Box pl="2" py="1" borderLeftWidth="2px" borderColor="primary" sx={{
      '>': {
        ':not(a):not(:last-child)': {
          mb: 2
        }
      }
    }} mdxType="Box">
      <Flex as="span" mdxType="Flex">
  <chakra.span pl="10px" pr="10px">
    <Center h="100%" mdxType="Center">
      <EnterpriseIcon mdxType="EnterpriseIcon" />
    </Center>
  </chakra.span>
  <Text pl="1" mdxType="Text">
    <b>
      This feature is only available with a{' '}
      <Link color={'tertiary'} href="https://www.apollographql.com/docs/graphos/enterprise/" mdxType="Link">
        GraphOS Enterprise plan
      </Link>
      .
    </b>{' '}
    If your organization doesn't have an Enterprise plan, you can test this
    functionality by signing up for a free{' '}
    <Link color={'tertiary'} href="https://www.apollographql.com/docs/graphos/org/plans/#enterprise-trials" mdxType="Link">
      Enterprise trial
    </Link>
    .
  </Text>
      </Flex>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      