import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeColumns = makeShortcode("CodeColumns");
const RuleExpansionPanel = makeShortcode("RuleExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`These rules identity inconsistencies in fields, types, arguments, etc across subgraphs. Such inconsistencies can disrupt or even break `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/federation/federated-types/composition"
      }}>{`composition`}</a>{`.`}</p>
    <h4 {...{
      "id": "compatibility"
    }}><a parentName="h4" {...{
        "href": "#compatibility"
      }}>{`Compatibility`}</a></h4>
    <p>{`In some cases, inconsistency rules also indicate the compatibility of checked types. Two types are compatible if one is a non-nullable version, a list version, a subtype, or a combination of any of these of the other.`}</p>
    <p>{`For example, the `}<inlineCode parentName="p">{`price`}</inlineCode>{` fields in the example subgraphs below are inconsistent and incompatible because they use completely different types (`}<inlineCode parentName="p">{`Float`}</inlineCode>{` vs `}<inlineCode parentName="p">{`String`}</inlineCode>{`):`}</p>
    <CodeColumns mdxType="CodeColumns">
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"Subgraph",
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
  price: Float #highlight-line
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"Subgraph",
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
  price: String #highlight-line
}

`}</code></pre>
    </CodeColumns>
    <p>{`These `}<inlineCode parentName="p">{`price`}</inlineCode>{` fields in the example subgraphs below are inconsistent but compatible since both use `}<inlineCode parentName="p">{`Float`}</inlineCode>{`s, but one is nullable and the other is the non-nullable list of `}<inlineCode parentName="p">{`Float`}</inlineCode>{`s.`}</p>
    <CodeColumns mdxType="CodeColumns">
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"Subgraph",
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
  price: Float #highlight-line
}

`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"Subgraph",
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
   price: [Float]! #highlight-line
}
`}</code></pre>
    </CodeColumns>
    <RuleExpansionPanel title="INCONSISTENT_ARGUMENT_PRESENCE" whatItDoes="Checks that an argument of a field or directive definition is present in all subgraphs." rationale="The supergraph schema only includes arguments that are exactly the same for all subgraphs that define its field or directive. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#input-types-and-field-arguments)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency): Float #highlight-line
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency, taxIncluded: Boolean): Float #highlight-line
}
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency, taxIncluded: Boolean): Float #highlight-line
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency, taxIncluded: Boolean): Float #highlight-line
}
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_BUT_COMPATIBLE_ARGUMENT_TYPE" whatItDoes="Checks that arguments (of a field, input field, or directive definition) have the exact same types in all subgraphs. This warning/error indicates the argument types are [compatible](#compatibility) but inconsistent." rationale="The supergraph schema only includes arguments that are exactly the same for all subgraphs that define its field or directive. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#input-types-and-field-arguments)" mdxType="RuleExpansionPanel">
      <br />
      <p>{`Because subgraph A's `}<inlineCode parentName="p">{`price`}</inlineCode>{` field expects a non-nullable `}<inlineCode parentName="p">{`Currency`}</inlineCode>{` argument type and subgraph B allows a nullable `}<inlineCode parentName="p">{`Currency`}</inlineCode>{` argument type, the following example violates the rule:`}</p>
      <br />
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency!): Float #highlight-line
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency): Float #highlight-line
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency!): Float #highlight-line
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price(currency: Currency!): Float #highlight-line
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_BUT_COMPATIBLE_FIELD_TYPE" whatItDoes="Checks that fields have the exact same types in all subgraphs. This warning/error indicates the field types are [compatible](#compatibility) but inconsistent." rationale="Inconsistent types can lead to discrepancies in the way data is retrieved and processed, resulting in unexpected client behavior." mdxType="RuleExpansionPanel">
      <br />
The following example violates the rule:
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price: Money #highlight-line
}

type Money {
  amount: Float!
  currency: Currency!
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price: Money! #highlight-line
}

type Money {
  amount: Float!
  currency: Currency!
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price: Money! #highlight-line
}

type Money {
  amount: Float!
  currency: Currency!
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  price: Money! #highlight-line
}

type Money {
  amount: Float!
  currency: Currency!
}

enum Currency {
  USD
  EUR
  GBP
  JPY
  AUD
  CAD
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_DEFAULT_VALUE_PRESENCE" whatItDoes="Checks that argument definitions (of a field, input field, or directive definition) consistently include—or consistently don't include—a default value in all subgraphs that define the argument." rationale="Inconsistent defaults can lead to discrepancies in the way data is retrieved and processed, resulting in unexpected client behavior." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  weight(kg: Float = 1.0): Float #highlight-line
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  weight(kg: Float): Float #highlight-line
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  weight(kg: Float = 1.0): Float #highlight-line
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  weight(kg: Float = 1.0): Float #highlight-line
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_DESCRIPTION" whatItDoes="Checks that a type's description is consistent across subgraphs." rationale="Inconsistent type descriptions can lead to inconsistent expectations around type values resulting in unexpected client behavior." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`"""
A type representing a product.
"""
type Product {
  id: ID!
  name: String
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`"""
An object representing a product.
"""
type Product {
  id: ID!
  name: String
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`"""
A type representing a product.
"""
type Product {
  id: ID!
  name: String
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`"""
A type representing a product.
"""
type Product {
  id: ID!
  name: String
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_ENTITY" whatItDoes="Checks that an object is consistently declared as an [entity](https://www.apollographql.com/docs/federation/entities) (has a `@key`) in all subgraphs in which the object is defined." rationale="If an object is only declared as an entity in some subgraphs, the federated schema won't have complete information about that entity." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product @key(fields: "id") { #highlight-line
  id: ID!
  name: String
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product { #highlight-line
  id: ID!
  stock: Int
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product @key(fields: "id") { #highlight-line
  id: ID!
  name: String
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product @key(fields: "id") { #highlight-line
  id: ID!
  stock: Int
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_ENUM_VALUE_FOR_INPUT_ENUM" whatItDoes="Checks that values of an input enum type are consistently defined in all subgraphs that declare the enum." rationale="When a value of an enum that is only used as an input type is defined in only some of the subgraphs that declare the enum, inconsistent values won't be merged into the supergraph. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#enums)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum ProductStatus {
  AVAILABLE
  SOLD_OUT
  BACK_ORDER #highlight-line
}

input ProductInput {
  name: String!
  status: ProductStatus!
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum ProductStatus {
  AVAILABLE
  SOLD_OUT
}

input ProductInput {
  name: String!
  status: ProductStatus!
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum ProductStatus {
  AVAILABLE
  SOLD_OUT
  BACK_ORDER
}

input ProductInput {
  name: String!
  status: ProductStatus!
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum ProductStatus {
  AVAILABLE
  SOLD_OUT
  BACK_ORDER
}

input ProductInput {
  name: String!
  status: ProductStatus!
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_ENUM_VALUE_FOR_OUTPUT_ENUM" whatItDoes="Checks that values of an output enum type are consistently defined in all subgraphs that declare the enum." rationale="When values of an output or unused enum type definition are inconsistent, all values are merged into the supergraph. Regardless, it can be helpful to set expectations by including all possible values in all subgraphs defining the enum. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#enums)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum OrderStatus {
  CREATED
  PROCESSING #highlight-line
  COMPLETED
}

type Order {
  name: String!
  status: OrderStatus!
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum OrderStatus {
  CREATED
  COMPLETED
}

type Order {
  name: String!
  status: OrderStatus!
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum OrderStatus {
  CREATED
  PROCESSING
  COMPLETED
}

type Order {
  name: String!
  status: OrderStatus!
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum OrderStatus {
  CREATED
  PROCESSING
  COMPLETED
}

type Order {
  name: String!
  status: OrderStatus!
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_EXECUTABLE_DIRECTIVE_LOCATIONS" whatItDoes="Checks that an executable directive definition is declared with consistent locations across all subgraphs." rationale="An executable directive is composed into the supergraph schema only when it is defined identically in all subgraphs. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#executable-directives)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @log(
  message: String!
) on QUERY  #highlight-line
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @log(
  message: String!
) on FIELD #highlight-line
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @log(
  message: String!
) on QUERY | FIELD
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @log(
  message: String!
) on QUERY | FIELD
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_EXECUTABLE_DIRECTIVE_PRESENCE" whatItDoes="Checks that an executable directive definition is declared in all subgraphs." rationale="An executable directive is composed into the supergraph schema only if it's defined in all subgraphs. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#executable-directives)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @modify(
  field: String!
) on FIELD
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`# 🦗🦗🦗
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @modify(
  field: String!
) on FIELD
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @modify(
  field: String!
) on FIELD
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_EXECUTABLE_DIRECTIVE_REPEATABLE" whatItDoes="Checks that an executable directive definition is marked `repeatable` in all subgraphs that define it." rationale="Unless an executable directive is defined as `repeatable` in all subgraphs, it won't be `repeatable` in the supergraph." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @validateLength(
  max: Int!
) repeatable on FIELD
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @validateLength(
  max: Int!
) on FIELD

`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @validateLength(
  max: Int!
) repeatable on FIELD
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @validateLength(
  max: Int!
) repeatable on FIELD
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_INPUT_OBJECT_FIELD" whatItDoes="Checks that a field of an input object definition is defined in all the subgraphs that declare the input object." rationale="The supergraph schema includes only the input object fields that all subgraphs define for the object. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#input-types-and-field-arguments)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`input ProductInput {
  name: String
  price: Float #highlight-line
}

input OrderInput {
  product: ProductInput
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`input ProductInput {
  name: String
}

input OrderInput {
  product: ProductInput
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`input ProductInput {
  name: String
  price: Float
}

input OrderInput {
  product: ProductInput
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`input ProductInput {
  name: String
  price: Float
}

input OrderInput {
  product: ProductInput
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_INTERFACE_VALUE_TYPE_FIELD" whatItDoes="Checks that a field of an interface [value type](https://www.apollographql.com/docs/federation/federated-types/sharing-types) (has no `@key` in any subgraph) is defined in all the subgraphs that declare the type." rationale="If different subgraphs contribute different fields to an interface type, any object types that implement that interface must define all contributed fields from all subgraphs. Otherwise, composition fails. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#object-union-and-interface-types)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`interface Product {
  id: ID!
  name: String
  cost: Float #highlight-line
}

type DigitalProduct implements Product {
  id: ID!
  name: String
  cost: Float
  size: Int 
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false {4}",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{4}": true
        }}>{`interface Product {
  id: ID!
  name: String
  # cost is not defined in the interface 
}

type PhysicalProduct implements Product {
  id: ID!
  name: String
  cost: Float
  weight: Float 
}

`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`interface Product {
  id: ID!
  name: String
  cost: Float #highlight-line
}

type DigitalProduct implements Product {
  id: ID!
  name: String
  cost: Float
  size: Int 
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`interface Product {
  id: ID!
  name: String
  cost: Float #highlight-line
}

type PhysicalProduct implements Product {
  id: ID!
  name: String
  cost: Float
  weight: Float 
}
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_NON_REPEATABLE_DIRECTIVE_ARGUMENTS" whatItDoes="Checks if a non-`repeatable` directive is applied to a schema element across different subgraphs with differing arguments." rationale="Inconsistent directive argument usage can lead to misunderstandings and potential issues in client applications." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  allProducts: [Product] @customDirective(orderBy: "name") #highlight-line
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  allProducts: [Product] @customDirective(orderBy: "price") #highlight-line
}
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  allProducts: [Product] @customDirective(orderBy: "name") #highlight-line
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  allProducts: [Product] @customDirective(orderBy: "name") #highlight-line
}
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_OBJECT_VALUE_TYPE_FIELD" whatItDoes="Checks that object [value types](https://www.apollographql.com/docs/federation/federated-types/sharing-types) (has no `@key` in any subgraph) declare the same fields in all subgraphs that declare the type." rationale="When an object value type includes differing fields across subgraphs, the supergraph schema includes the union of all fields. Depending on which subgraph executes the query, [omitted fields may be unresolvable](https://www.apollographql.com/docs/federation/federated-types/sharing-types#omitting-fields). You can include the same types as shown below or check out [Solutions for unresolvable fields](https://www.apollographql.com/docs/federation/federated-types/composition/#solutions-for-unresolvable-fields)." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID! @shareable 
  name: String @shareable 
  price: Float #highlight-line
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID! @shareable 
  name: String @shareable 
}

`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product @shareable {
  id: ID!
  name: String
  price: Float #highlight-line
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product @shareable {
  id: ID!
  name: String
  price: Float #highlight-line
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_RUNTIME_TYPES_FOR_SHAREABLE_RETURN" whatItDoes="Checks that a `@shareable` field returns consistent sets of runtime types in all subgraphs in which it's defined." rationale="Each subgraph's resolver for a `@shareable` field should behave identically. Otherwise, requests might return inconsistent results depending on which subgraph resolves the field. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/sharing-types#using-shareable)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  details: Details @shareable #highlight-line
}

type Details {
  size: String #highlight-line
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  details: Details @shareable #highlight-line
}

type Details {
  weight: Float #highlight-line
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  details: Details @shareable #highlight-line
}

type Details {
  size: String #highlight-line
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`type Product {
  id: ID!
  name: String
  details: Details @shareable #highlight-line
}

type Details {
  size: String #highlight-line
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_TYPE_SYSTEM_DIRECTIVE_LOCATIONS" whatItDoes="Checks that a type system directive definition is declared with consistent locations across subgraphs." rationale="To ensure consistent expectations, it's best that all definitions declare the same locations. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#type-system-directives)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @customDirective(
  message: String!
) on OBJECT | FIELD_DEFINITION  #highlight-line
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @customDirective(
  message: String!
) on FIELD_DEFINITION #highlight-line
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @customDirective(
  message: String!
) on OBJECT | FIELD_DEFINITION
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`directive @customDirective(
  message: String!
) on OBJECT | FIELD_DEFINITION
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_TYPE_SYSTEM_DIRECTIVE_REPEATABLE" whatItDoes="Checks that a type system directive definition is marked `repeatable` in all subgraphs that declare the directive and will be `repeatable` in the supergraph." rationale="To ensure consistent expectations, directives should have consistent definitions across subgraphs, including whether they are `repeatable`. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#type-system-directives)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @customDirective on OBJECT
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @customDirective repeatable on OBJECT
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @customDirective repeatable on OBJECT
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @customDirective repeatable on OBJECT
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="INCONSISTENT_UNION_MEMBER" whatItDoes="Checks that a member of a union definition is defined in all subgraphs that declare the union." rationale="When a union definition has inconsistent members, the supergraph schema includes all members in the union definition. Nevertheless, to ensure consistent expectations, it's best that all union definitions declare the same members across subgraphs. [Learn more.](https://www.apollographql.com/docs/federation/federated-types/composition#object-union-and-interface-types)" mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Service {
  id: ID!
  description: String
}

union SearchResult = Product | Service #highlight-line
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

union SearchResult = Product #highlight-line
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Service {
  id: ID!
  description: String
}

union SearchResult = Product | Service #highlight-line
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Service {
  id: ID!
  description: String
}

union SearchResult = Product | Service #highlight-line
`}</code></pre>
    </RuleExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      