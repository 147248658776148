import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RuleExpansionPanel = makeShortcode("RuleExpansionPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <RuleExpansionPanel title="DIRECTIVE_COMPOSITION" whatItDoes="Checks for issues when composing custom directives." mdxType="RuleExpansionPanel" />
    <RuleExpansionPanel title="MERGED_NON_REPEATABLE_DIRECTIVE_ARGUMENTS" whatItDoes="Checks if a non-[`repeatable`](https://spec.graphql.org/draft/#sel-GAHnBRVABeBoB_2T) directive has been applied to the same schema element in different subgraphs with different arguments. [Learn more.](https://www.apollographql.com/docs/apollo-server/schema/directives/#in-subgraphs)" rationale="Arguments should be consistent across a non-[`repeatable`](https://spec.graphql.org/draft/#sel-GAHnBRVABeBoB_2T) directive's usage. If arguments differ, it may be a sign that subgraph owners need to communicate about the directive's usage. If the arguments need to differ, consider using a [`repeatable`](https://spec.graphql.org/draft/#sel-GAHnBRVABeBoB_2T) directive." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  products: [Product] @customDirective(orderBy: ["name"]) #highlight-line
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  products: [Product] @customDirective(orderBy: ["price"]) #highlight-line
}
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  products: [Product] @customDirective(orderBy: ["name", "price"]) #highlight-line
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product {
  id: ID!
  name: String
}

type Query {
  products: [Product] @customDirective(orderBy: ["name", "price"]) #highlight-line
}
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="NO_EXECUTABLE_DIRECTIVE_INTERSECTION" whatItDoes="Checks for executable directive definitions with no shared [locations](https://spec.graphql.org/June2018/#ExecutableDirectiveLocation) across subgraphs." rationale="Directives must only be used in the locations they are declared to belong in. If the same executable directive is defined with different locations in different subgraphs, it may be a sign that subgraph owners need to communicate about the directive's usage." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @log(message: String!) on QUERY #highlight-line
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @log(message: String!) on FIELD #highlight-line
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @log(message: String!) on QUERY | FIELD
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`directive @log(message: String!) on QUERY | FIELD
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="FROM_SUBGRAPH_DOES_NOT_EXIST" whatItDoes="Checks that the source subgraph specified by [`@override`](https://www.apollographql.com/docs/federation/federated-types/federated-directives/#override) directive exists." rationale="The [`@override`](https://www.apollographql.com/docs/federation/federated-types/federated-directives/#override) directive indicates that an object field is now resolved by a different subgraph. The directive can't work unless you specify an existing subgraph to resolve the field from." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false {3}",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{3}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  inStock: Boolean! @override(from: "Subgraph B")
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`# Subgraph B doesn't exist
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false {3}",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{3}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  inStock: Boolean! @override(from: "Subgraph B")
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product @key(fields: "id") {
  id: ID!
  inStock: Boolean!
}
`}</code></pre>
    </RuleExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      