import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/router/"
      }}>{`The Apollo Router`}</a>{` supports a collection of premium features specific to GraphOS. These include:`}</p>
    <ul>
      <li parentName="ul">{`Real-time updates via `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/executing-operations/subscription-support/"
        }}>{`GraphQL subscriptions`}</a></li>
      <li parentName="ul">{`Authentication of inbound requests via `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/authn-jwt/"
        }}>{`JSON Web Token (JWT)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/authorization"
        }}>{`Authorization of specific fields and types`}</a>{` through the `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/authorization#requiresscopes"
        }}><inlineCode parentName="a">{`@requiresScopes`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/authorization#authenticated"
        }}><inlineCode parentName="a">{`@authenticated`}</inlineCode></a>{`, and `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/authorization#policy"
        }}><inlineCode parentName="a">{`@policy`}</inlineCode></a>{` directives`}</li>
      <li parentName="ul">{`Incremental migration between subgraphs through `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/federation/entities-advanced/#incremental-migration-with-progressive-override"
        }}>{`the progressive `}<inlineCode parentName="a">{`@override`}</inlineCode>{` directive`}</a>{`.`}</li>
      <li parentName="ul">{`Redis-backed `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/distributed-caching/"
        }}>{`distributed caching of query plans and persisted queries`}</a></li>
      <li parentName="ul">{`Redis-backed `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/entity-caching/"
        }}>{`entity caching of subgraph responses`}</a></li>
      <li parentName="ul">{`Custom request handling in any language via `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/customizations/coprocessor/"
        }}>{`external coprocessing`}</a></li>
      <li parentName="ul">{`Mitigation of potentially malicious requests via `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/operation-limits"
        }}>{`operation limits`}</a>{` and `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/graphos/operations/persisted-queries"
        }}>{`safelisting`}</a></li>
      <li parentName="ul">{`Custom instrumentation and telemetry, including `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/configuration/telemetry/instrumentation/spans/#attributes"
        }}>{`custom attributes for spans`}</a>{`.`}</li>
      <li parentName="ul">{`An `}<a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/router/enterprise-features/#offline-enterprise-license"
        }}>{`offline Enterprise license`}</a>{` that enables running the router with Enterprise features when disconnected from the internet.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      