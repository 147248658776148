import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Note mdxType="Note">
      <p>{`The code blocks below use TypeScript by default. You can use the dropdown menu above each code block to switch to JavaScript.`}</p>
      <p>{`If you're using JavaScript, use `}<inlineCode parentName="p">{`.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`.jsx`}</inlineCode>{` file extensions wherever `}<inlineCode parentName="p">{`.ts`}</inlineCode>{` and `}<inlineCode parentName="p">{`.tsx`}</inlineCode>{` appear.`}</p>
    </Note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      