import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const Caution = makeShortcode("Caution");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="How to obtain a personal API key" mdxType="ExpansionPanel">
      <Caution mdxType="Caution">
        <p>{`Personal API keys are secret credentials. Never share them with others or commit them to version control. Delete and replace API keys that you believe are compromised.`}</p>
      </Caution>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Go to `}<a parentName="p" {...{
              "href": "https://studio.apollographql.com/user-settings"
            }}>{`studio.apollographql.com/user-settings`}</a>{`.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`In the Personal API Key section, click `}<strong parentName="p">{`Create New Key`}</strong>{`. Give your key a name, such as `}<inlineCode parentName="p">{`Local development laptop`}</inlineCode>{`. This helps you keep track of each API key's use.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Copy the key's value. For security, you cannot view an API key's value in Studio after creating it.`}</p>
        </li>
      </ol>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      