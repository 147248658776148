import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RuleExpansionPanel = makeShortcode("RuleExpansionPanel");
const CodeColumns = makeShortcode("CodeColumns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <RuleExpansionPanel title="OVERRIDE_DIRECTIVE_CAN_BE_REMOVED" whatItDoes="Checks that a field with the [`@override`](https://www.apollographql.com/docs/federation/federated-types/federated-directives/#override) directive no longer exists in a source subgraph." rationale="If a field with the [`@override`](https://www.apollographql.com/docs/federation/federated-types/federated-directives/#override) directive no longer exists in a source subgraph, the directive can be safely removed." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false {3}",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{3}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  inStock: Boolean! @override(from: "Subgraph B")
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
}
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false {3}",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{3}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  inStock: Boolean!
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
}
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="OVERRIDDEN_FIELD_CAN_BE_REMOVED" whatItDoes="Checks if a field has been overridden by another subgraph." rationale="You should consider removing overridden fields to avoid confusion." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false {3}",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{3}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  inStock: Boolean! @override(from: "Subgraph B")
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false {4}",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{4}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
  inStock: Boolean!
}
`}</code></pre>
      <br />
Use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
  inStock: Boolean!
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
}
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="OVERRIDE_MIGRATION_IN_PROGRESS" whatItDoes="Checks if a field migration is in progress." rationale="You should complete a field migration." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false {3}",
          "title": "\"❌",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{3}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  inStock: Boolean! @override(from: "Subgraph B", label: "percent(50)")
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false {4}",
          "title": "\"❌",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false",
          "{4}": true
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
  inStock: Boolean!
}
`}</code></pre>
      <br />
After completing the migration, use instead:
      <br />
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "A\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
  inStock: Boolean!
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-graphql",
          "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false",
          "title": "\"✅",
          "Subgraph": true,
          "B\"": true,
          "disableCopy": "true",
          "showLineNumbers": "false"
        }}>{`type Product @key(fields: "id") {
  id: ID!
  name: String!
}
`}</code></pre>
    </RuleExpansionPanel>
    <RuleExpansionPanel title="UNUSED_ENUM_TYPE" whatItDoes="Checks if an enum type is defined but no field or argument in any subgraph references it." rationale="If the enum is defined, it should be used or removed." mdxType="RuleExpansionPanel">
      <p>{`The following example violates the rule:`}</p>
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph A\" disableCopy=true showLineNumbers=false",
            "title": "\"❌",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false"
          }}>{`enum ProductStatus {
  AVAILABLE
  SOLD_OUT
}

type Product {
  id: ID!
  name: String
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"❌ Subgraph B\" disableCopy=true showLineNumbers=false  {4}",
            "title": "\"❌",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false",
            "": true,
            "{4}": true
          }}>{`type Order {
  id: ID!
  product: Product
  status: String
}
`}</code></pre>
      </CodeColumns>
      <br />
Use instead:
      <br />
      <CodeColumns mdxType="CodeColumns">
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph A\" disableCopy=true showLineNumbers=false {9}",
            "title": "\"✅",
            "Subgraph": true,
            "A\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false",
            "{9}": true
          }}>{`enum ProductStatus {
  AVAILABLE
  SOLD_OUT
}

type Product {
  id: ID!
  name: String
  status: ProductStatus
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-graphql",
            "metastring": "title=\"✅ Subgraph B\" disableCopy=true showLineNumbers=false {4}",
            "title": "\"✅",
            "Subgraph": true,
            "B\"": true,
            "disableCopy": "true",
            "showLineNumbers": "false",
            "{4}": true
          }}>{`
type Order {
  id: ID!
  product: Product
  status: ProductStatus
}
`}</code></pre>
      </CodeColumns>
    </RuleExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      