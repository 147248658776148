import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Note mdxType="Note">
      <p>{`Schema proposals—even `}<a parentName="p" {...{
          "href": "/graphos/delivery/schema-proposals/#proposal-statuses"
        }}>{`approved`}</a>{` ones—don't deploy any changes to your graph. Once a proposal is approved, your team must `}<a parentName="p" {...{
          "href": "/graphos/delivery/schema-proposals/implementation"
        }}>{`implement and publish the changes`}</a>{`.`}</p>
    </Note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      