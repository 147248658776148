import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PreviewFeature = makeShortcode("PreviewFeature");
const TrackableLink = makeShortcode("TrackableLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <PreviewFeature mdxType="PreviewFeature">
      <p>{`Cloud Dedicated is currently in invite-only `}<a parentName="p" {...{
          "href": "/graphos/cloud-routing/dedicated#private-preview"
        }}>{`preview`}</a>{`. Don't hesitate to `}<TrackableLink href="https://www.apollographql.com/contact-sales?type=dedicated&referrer=docs" eventName="content_contact_cloud" mdxType="TrackableLink">{`get in touch`}</TrackableLink>{` if you'd like to request access or have any questions or feedback.`}</p>
    </PreviewFeature>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      