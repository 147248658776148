import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SchemaProposalImplementationNote = makeShortcode("SchemaProposalImplementationNote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Once a proposal receives the `}<a parentName="p" {...{
        "href": "/graphos/delivery/schema-proposals/configuration#required-approvals"
      }}>{`minimum number of approvals`}</a>{`, its status changes to `}<strong parentName="p">{`Approved`}</strong>{`.`}</p>
    <p>{`Revisions made to an approved proposal don't change the `}<strong parentName="p">{`Approved`}</strong>{` status of the proposal.
To set the proposal's status to `}<strong parentName="p">{`Open for feedback`}</strong>{` or another status, `}<a parentName="p" {...{
        "href": "/graphos/delivery/schema-proposals/creation#change-proposal-status"
      }}>{`manually change it`}</a>{` from the proposal's overview page.`}</p>
    <p>{`Once a proposal is approved, your team can begin implementing the approved changes.`}</p>
    <SchemaProposalImplementationNote mdxType="SchemaProposalImplementationNote" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      