import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpansionPanel = makeShortcode("ExpansionPanel");
const Caution = makeShortcode("Caution");
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExpansionPanel title="How to obtain a graph API key" mdxType="ExpansionPanel">
      <Caution mdxType="Caution">
        <p>{`API keys are secret credentials. Never share them outside your organization or commit them to version control. Delete and replace API keys that you believe are compromised.`}</p>
      </Caution>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Go to `}<a parentName="p" {...{
              "href": "https://studio.apollographql.com/?referrer=docs-content"
            }}>{`studio.apollographql.com`}</a>{` and click the graph you want to obtain an API key for.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`If a `}<strong parentName="p">{`Publish your Schema`}</strong>{` dialog appears, copy the protected value that appears after `}<inlineCode parentName="p">{`APOLLO_KEY=`}</inlineCode>{` in the example code block (it begins with `}<inlineCode parentName="p">{`service:`}</inlineCode>{`), and you're all set.`}</p>
          <p parentName="li">{`Otherwise, proceed to the next step.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Open your graph's Settings page and select the API Keys tab. Click `}<strong parentName="p">{`Create New Key`}</strong>{`. Give your key a name, such as `}<inlineCode parentName="p">{`Production`}</inlineCode>{`. This helps you keep track of each API key's use.`}</p>
          <Note mdxType="Note">
            <p parentName="li">{`If you don't see the API Keys tab, you don't have sufficient permissions for your graph. Only organization members with the `}<strong parentName="p">{`Org Admin`}</strong>{` or `}<strong parentName="p">{`Graph Admin`}</strong>{` role can manage graph API keys. `}<a parentName="p" {...{
                "href": "/graphos/org/members/"
              }}>{`Learn more about member roles.`}</a></p>
          </Note>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Copy the key's value. For security, you cannot view an API key's value in Studio after creating it.`}</p>
        </li>
      </ol>
    </ExpansionPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      