import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Note mdxType="Note">
      <p>{`In the examples below, we use top-level `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function"
        }}><inlineCode parentName="a">{`await`}</inlineCode></a>{` calls to start our server asynchronously. If you'd like to see how we set this up, check out the `}<a parentName="p" {...{
          "href": "/apollo-server/getting-started#step-2-install-dependencies"
        }}>{`Getting Started`}</a>{` guide for details.`}</p>
    </Note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      