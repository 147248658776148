import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To display schema check results on pull requests correctly, you need to make sure Rover associates the schema check execution with the pull request's `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{` commit, as opposed to the merge commit that GitHub adds. To guarantee this, set the `}<inlineCode parentName="p">{`APOLLO_VCS_COMMIT`}</inlineCode>{` environment variable in your action's configuration, like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`env:
  APOLLO_VCS_COMMIT: \${{ github.event.pull_request.head.sha }}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      